<template>
  <div class="inner-layout-800">

    <div class="detailP-subject-box">
      <div class="category">{{ item.categoryName }} / {{ item.petName }}</div>
      <div class="heading-1">{{ item.title }}</div>
    </div>

    <div class="detailP-profile-lable-box">
      <profile :profileType="'detail'" :item="item"/>

      <icon-label :item="item"/>
    </div>

    <div class="detailP-thumb">
      <image-alt :src="item.src" altType="detail"/>
    </div>

    <div v-html="item.content" class="detailP-content"/>

    <quick-box :cateCode="20" :item="item" />

    <recommend/>

    <div class="horizon-bar mb-60"></div>

    <comment ref="comments" :cateCode="20" :count="item.comment"/>

  </div>
</template>

<script>
import {mapActions} from "vuex"
import viewMixin from "@/mixins/viewMixin"
export default {
  name: "lab",
  mixins: [viewMixin],
  data: function () {
    return {
      idx: this.$route.params.idx,
      item: {},
      isOpenLoginPopup: false,
      isOpenPopup: false,
      popupMsg: null,
    }
  },
  created() {
    this.setItem();
    setTimeout(() => {
      const tagDivArr = document.getElementsByClassName('tag-div')
      if (tagDivArr) {
        for (let tagDiv of tagDivArr) {
          // tagDiv.addEventListener('click', (event) => {
          //   event.target.nextElementSibling.style.display =  event.target.nextElementSibling.style.display === 'none' ? 'flex' : 'none'
          // })
          tagDiv.addEventListener('mouseenter', (event) => {
            event.target.querySelector('a.tag-product-box').style.display = 'flex'
          })
          tagDiv.addEventListener('mouseleave', (event) => {
            event.target.querySelector('a.tag-product-box').style.display = 'none'
          })
        }
      }
    }, 1000)
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      if (!this.idx) await this.$router.push('/lab');
      const { result, data } = await this.$api.getBoardDetail({ cateCode: 20, boIdx: this.idx })
      switch (result) {
        case 'success':
          this.item = data
          break;
        case 'empty':
          this.$router.push('/lab').then()
          break;
        case 'deleted':
          this.setMsgPopup({
            type: 'alert',
            message: this.$msg('ok.deleted'),
            okay: '',
            okayCallback: () => {
              this.setMsgPopup()
              this.$router.go(-1)
            },
          })
          break;
        default:
      }
    },
  }
}
</script>
